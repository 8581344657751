import React from 'react';
import html2canvas from 'html2canvas';

const ShareButtons = ({ roast }) => {
    const captureAndShare = async () => {
        const roastElement = document.querySelector('.roast-container'); // Select the roast container
        if (roastElement) {
            const canvas = await html2canvas(roastElement);
            const dataUrl = canvas.toDataURL('image/png'); // Convert canvas to data URL

            // Create the base URL for sharing
            const shareUrl = `https://roastmystyle.com/quiz?image=${encodeURIComponent(dataUrl)}`;

            // Share on WhatsApp with the updated message
            const whatsappUrl = `https://api.whatsapp.com/send?text=I just got roasted! Get roasted too! ${shareUrl}`;

            // Share on Twitter
            const twitterUrl = `https://twitter.com/intent/tweet?text=I just got roasted! Take a look&url=${encodeURIComponent(shareUrl)}`;

            // Instagram doesn't support direct URL sharing, so you can only share the image manually or suggest users share it via a post
            const instagramUrl = `https://www.instagram.com/create/story/?text=${encodeURIComponent(roast)}`;

            // Use the Web Share API if supported
            if (navigator.share) {
                navigator.share({
                    title: 'Check out my roast!',
                    text: 'I just got roasted! Take a look.',
                    url: shareUrl,
                }).catch(console.error);
            } else {
                // Fallback: provide shareable links for different platforms
                alert(`
                    Share this link:
                    WhatsApp: ${whatsappUrl}
                    Twitter: ${twitterUrl}
                    Instagram (Manual): ${instagramUrl}
                `);
            }
        }
    };

    const shareRoastAsImage = async () => {
        const roastElement = document.querySelector('.roast-container'); // Select the roast container
        if (roastElement) {
            const canvas = await html2canvas(roastElement);
            const dataUrl = canvas.toDataURL('image/png'); // Convert canvas to data URL

            // Create a link element to download the image
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'roastmystyle.png'; // Set the filename
            link.click(); // Trigger the download
        }
    };

    return (
        <div>
            <button onClick={captureAndShare} className="share-button">Share Your Roast!</button>
            <button onClick={shareRoastAsImage} className="share-button">Download Roast as Image</button>
        </div>
    );
};

export default ShareButtons;