import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import './App.css';
import Quiz from './Quiz';
import Logo from './Logo';
import CookieConsent from './CookieConsent';

function App() {
    return (
        <Router>
            <div className="App">
                <header className="navbar">
                    <Logo />
                    <nav className="nav-links">
                        <Link to="/quiz" className="nav-link"></Link>
                    </nav>
                </header>
                <CookieConsent />
                <Routes>
                    <Route path="/" element={<Navigate to="/quiz" />} />
                    <Route path="/quiz" element={<Quiz />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
