import React from 'react';
import './ImageStyles.css'; // Ensure this path is correct

const BouncingBalls = () => {
    return (
        <div className="bouncing-balls-container">
            {Array.from({ length: 20 }).map((_, index) => (
                <div key={index} className={`ball ball-${index + 1}`} />
            ))}
        </div>
    );
};

export default BouncingBalls;