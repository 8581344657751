import React, { useEffect, useState } from 'react';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import { signInAnonymously } from 'firebase/auth';
import { db, auth } from './firebase';
import './ImageStyles.css'; // Import the new CSS file
import ShareButtons from './ShareButtons'; // Import the ShareButtons component
import BouncingBalls from './BouncingBalls'; // Import the BouncingBalls component
import instagramLogo from './background/instagram.png'; // Import Instagram logo
import xLogo from './background/X-Logo.jpeg'; // Import X logo

// Import images
import womenImage from './QUIZ/Q1/a.jpeg';
import menImage from './QUIZ/Q1/b.jpg';
import genderneutralImage from './QUIZ/Q1/c.jpeg';
import budgetspendingImage from './QUIZ/Q2a/a.jpeg';
import moderatespendingImage from './QUIZ/Q2a/b.jpeg';
import highspendingImage from './QUIZ/Q2a/c.jpeg';
import budgetspendingsImage from './QUIZ/Q2b/a.jpeg';
import moderatespendingsImage from './QUIZ/Q2b/b.jpeg';
import highspendingsImage from './QUIZ/Q2b/c.jpeg';
import budgetsspendingImage from './QUIZ/Q2c/a.jpeg';
import moderatesspendingImage from './QUIZ/Q2c/b.png';
import highsspendingImage from './QUIZ/Q2c/c.jpeg';
import MinimalisticstyleImage from './QUIZ/Q3/a.jpg';
import HypebeaststyleImage from './QUIZ/Q3/b.jpg';
import ExperimentalstyleImage from './QUIZ/Q3/c.jpg';
import bornafter2005Image from './QUIZ/Q4/a.jpeg';
import bornbetween1997and2005Image from './QUIZ/Q4/b.jpeg';
import bornbefore1997Image from './QUIZ/Q4/c.jpeg';
import influencersImage from './QUIZ/Q5/a.png';
import HypebeastImage from './QUIZ/Q5/b.jpeg';
import SSenseImage from './QUIZ/Q5/c.jpeg';
import shoesImage from './QUIZ/Q6/a.jpeg';
import apperalImage from './QUIZ/Q6/b.jpeg';
import accessoriesImage from './QUIZ/Q6/c.jpeg';
import retailImage from './QUIZ/Q7/a.jpg';
import websiteImage from './QUIZ/Q7/b.png';
import marketplaceImage from './QUIZ/Q7/c.png';
import newyorkImage from './QUIZ/Q8/a.jpeg';
import londonImage from './QUIZ/Q8/b.jpeg';
import seoulImage from './QUIZ/Q8/c.jpeg';
import LosAngelesImage from './QUIZ/Q9/a.jpeg';
import ParisImage from './QUIZ/Q9/b.png';
import tokyoImage from './QUIZ/Q9/c.jpeg';

const navbarColors = {
    Q1: 'rgba(32, 33, 27, 0.9)', // Eerie Black
    Q2a: 'rgba(32, 33, 27, 0.9)', // Eerie Black
    Q2b: 'rgba(32, 33, 27, 0.9)', // Eerie Black
    Q2c: 'rgba(32, 33, 27, 0.9)', // Eerie Black
    Q3: 'rgba(207, 238, 158, 0.9)', // Mindaro
    Q4: 'rgba(246, 252, 236, 0.9)', // Ivory
    Q5: 'rgba(245, 171, 187, 0.9)', // Cherry Blossom Pink
    Q6: 'rgba(32, 33, 27, 0.9)', // Eerie Black
    Q7: 'rgba(57, 48, 50, 0.9)', // Black Olive
    Q8: 'rgba(246, 252, 236, 0.9)', // Ivory
    Q9: 'rgba(32, 33, 27, 0.9)', // Eerie Black
};

const Quiz = () => {
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [userAnswers, setUserAnswers] = useState([]);
    const [roast, setRoast] = useState('');
    const [user, setUser] = useState(null);
    const [currentQuestionId, setCurrentQuestionId] = useState('Q1');
    const [loading, setLoading] = useState(false); // New loading state
    const [roastText, setRoastText] = useState(''); // New state for roast text
    const [showThankYouPage, setShowThankYouPage] = useState(false); // New state for showing the thank-you page
    const [showRoast, setShowRoast] = useState(false); // New state to control roast display
    const [showBouncingBalls, setShowBouncingBalls] = useState(false);
    const [showFrontPage, setShowFrontPage] = useState(true); // State to manage front page visibility
    const [questions, setQuestions] = useState([
        { id: 'Q1', text: 'What do you prefer?', label: 'Choose your preference:' },
        { id: 'Q2', text: 'What brand?', label: 'Select a brand:' },
        { id: 'Q2b', text: 'What do you prefer?', label: 'Choose your preference:' },
        { id: 'Q2c', text: 'What do you prefer?', label: 'Choose your preference:' },
        { id: 'Q3', text: 'What do you prefer?', label: 'Choose your preference:' },
        { id: 'Q4', text: 'What do you prefer?', label: 'Choose your preference:' },
        { id: 'Q5', text: 'What do you prefer?', label: 'Choose your preference:' },
        { id: 'Q6', text: 'What do you prefer?', label: 'Choose your preference:' },
        { id: 'Q7', text: 'What do you prefer?', label: 'Choose your preference:' },
        { id: 'Q8', text: 'What do you prefer?', label: 'Choose your preference:' },
        { id: 'Q9', text: 'What do you prefer?', label: 'Choose your preference:' },
        // Add more questions as needed
    ]);
        const questionBackgroundColors = {
        Q1: 'linear-gradient(90deg, #20221b, #cfee9e, #F6FCEE, #F5ABBB, #394032)', // Light Green to Dark Green with more contrast
        Q2a: 'linear-gradient(135deg, #F5abbb, #20221b)', // Ivory to Black with more contrast
        Q2b: 'linear-gradient(135deg, #20221b, #cfee9e, #20221b)', // Light Green to Dark Green with more contrast
        Q2c: 'linear-gradient(135deg, #394032, #20221b)', // Light Green to Dark Green with more contrast
        Q3: 'linear-gradient(180deg, #20221b ,#F6FCee, #cfee9e, #394032)', // Cherry Blossom Pink to Pastel Pink with more contrast
        Q4: 'linear-gradient(90deg, #394032, #F6FCEE, #20221b)', // Light Green to Dark Green with more contrast
        Q5: 'linear-gradient(90deg, #F6FCEE, #CFEE9E, #F6FCEE)', // Light Green to Dark Green with more contrast
        Q6: 'linear-gradient(180deg, #F5ABBB, #CFEE9E)', // Light Green to Dark Green with more contrast
        Q7: 'linear-gradient(90deg, #F6FCEE, #394032, #394032, #F5ABBB)', // Light Green to Dark Green with more contrast
        Q8: 'linear-gradient(90deg, #394032, #20221b)', // Black to Light Green with more contrast
        Q9: 'linear-gradient(90deg, #F5ABBB, #20221b, #cfee9e, #F6FCEE, #394032)', // Dark Green to Light Green with more contrast
    };

    const animatedBackgrounds = {
        Q1: null, // No animated background for Q1
        Q2a: <div className="animated-background bathtub"></div>, // Bathtub for Q2a
        Q2b: <div className="animated-background bathtub"></div>, // Bathtub for Q2b
        Q2c: <div className="animated-background bathtub"></div>, // Bathtub for Q2c
        Q3: <div className="animated-background flying-cow"></div>, // Flying cow for Q3
        Q4: null, // No animated background for Q4
        Q5: <div className="animated-background rainbow"></div>, // Rainbow for Q5
        Q6: <div className="animated-background eye"></div>, // Eye for Q6
        Q7: [<div className="animated-background moving-box" style={{top: '10%', left: '10%'}}></div>, <div className="animated-background additional-moving-box" style={{top: '20%', left: '20%'}}></div>, <div className="animated-background additional-moving-box round" style={{top: '30%', left: '30%'}}></div>, <div className="animated-background additional-moving-box round" style={{top: '40%', left: '40%'}}></div>, <div className="animated-background additional-moving-box" style={{top: '50%', left: '50%'}}></div>, <div className="animated-background moving-box" style={{top: '60%', left: '60%'}}></div>, <div className="animated-background additional-moving-box" style={{top: '70%', left: '70%'}}></div>, <div className="animated-background additional-moving-box round" style={{top: '80%', left: '80%'}}></div>, <div className="animated-background additional-moving-box round" style={{top: '90%', left: '90%'}}></div>, <div className="animated-background additional-moving-box" style={{top: '100%', left: '100%'}}></div>], // Eye for Q7
        Q8:  null, // No animated background for Q8
        Q9: <div className="animated-background flying-dog"></div>, // Dog for Q9
        // Add more questions with their respective animated backgrounds
    };

    useEffect(() => {
        console.log("Environment Variables:", process.env);
        console.log("API Key:", process.env.REACT_APP_XAI_API_KEY);
        const authenticateUser = async () => {
            try {
                const result = await signInAnonymously(auth);
                setUser(result.user);
                console.log("User authenticated successfully:", result.user);
            } catch (error) {
                console.error("Error authenticating user:", error);
            }
        };

        authenticateUser();

        const fetchInitialQuestion = async () => {
            try {
                const questionDoc = await getDoc(doc(db, 'Questions', 'Q1'));
                if (questionDoc.exists()) {
                    setCurrentQuestion(questionDoc.data());
                } else {
                    console.error("No such document for Q1!");
                }
            } catch (error) {
                console.error("Error fetching initial question:", error);
            }
        };
        
        fetchInitialQuestion();
    }, []);

    useEffect(() => {
        console.log("Current Question:", currentQuestion);
    }, [currentQuestion]);

    // Function to handle starting the quiz
    const startQuiz = () => {
        setShowFrontPage(false); // Hide the front page
        setCurrentQuestionId('Q1'); // Set the current question to the first question
    };
    
    const addQuestion = (newQuestion) => {
        setQuestions((prevQuestions) => [
            ...prevQuestions,
            { id: `Q${prevQuestions.length + 1}`, text: newQuestion }
        ]);
    };

    const fetchRoast = async () => {
        if (currentQuestionId === 'Q9') { // Check if it's Question 9
            setLoading(true); // Set loading to true
        }

        try {
            const roastResponse = await fetch('https://api.x.ai/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_XAI_API_KEY}`,
                },
                body: JSON.stringify({
                    model: "grok-beta",
                    messages: [{ role: "user", content: `create a 100-word mean roast that tells a flowing story: ${userAnswers.join(', ')}` }]
                }),
            });

            const roastBody = await roastResponse.json();

            if (roastResponse.ok) {
                setRoast(roastBody.choices[0].message.content);
                setRoastText(roastBody.choices[0].message.content); // Set roast text
                setLoading(false); // Stop loading
            } else {
                console.error("Error fetching roast:", roastBody);
                alert("There was an error fetching your roast. Please try again.");
            }
        } catch (error) {
            console.error("Error connecting to xAI API for roast:", error);
            alert("Error connecting to xAI for roast. Please check your connection.");
        }
    };

    const saveUserAnswer = async (userId, answers) => {
        try {
            await setDoc(doc(db, 'UserAnswers', userId), { answers });
            console.log("User answers saved successfully!");
        } catch (error) {
            console.error("Error saving user answers:", error);
        }
    };

    const handleOptionSelect = async (option) => {
        if (!currentQuestion) return;

        setUserAnswers((prevAnswers) => {
            const newAnswers = [...prevAnswers, option];
            const userId = `user_${Date.now()}`;
            saveUserAnswer(userId, newAnswers);
            return newAnswers;
        });

        // Check if the current question is Q9 and ensure the roast is fetched and shown
        if (currentQuestionId === 'Q9') {
            setShowRoast(true); // Ensure the roast is shown instead of Q9
            fetchRoast(); // Fetch the roast immediately after Q9 is answered
            return; // Prevent further actions after Q9 is handled
        }

        // Existing logic for handling other questions
        if (!currentQuestion.nextQuestions) {
            console.error("nextQuestions is undefined");
            return;
        }

        const nextQuestionId = currentQuestion.nextQuestions[option.toLowerCase()] || currentQuestion.nextQuestions.next;

        if (nextQuestionId) {
            await fetchNextQuestion(nextQuestionId);
        } else {
            console.error("Next question ID is undefined!");
        }
    };
    

useEffect(() => {
    if (showThankYouPage) {
        fetchRoast(); // Fetch the roast after showing the new page
    }
}, [showThankYouPage]); // Dependency on the showThankYouPage state

    const fetchNextQuestion = async (nextQuestionId) => { // Update 2
        try {
            const questionDoc = await getDoc(doc(db, 'Questions', nextQuestionId));
            if (questionDoc.exists()) {
                setCurrentQuestion(questionDoc.data());
                setCurrentQuestionId(nextQuestionId); // Update 2
            } else {
                console.error("No such document for the next question!");
            }
        } catch (error) {
            console.error("Error fetching next question:", error);
        }
    };

    const getImageForOption = (option) => {
        switch (option.toLowerCase()) {
            case 'women':
                return {src: womenImage, shape: 'circle'};
            case 'men':
                return {src: menImage, shape: 'circle'};
            case 'genderneutral':
                return {src: genderneutralImage, shape: 'circle'};
            case 'budget spending':
                return {src: budgetspendingImage, shape: 'rectangle'};
            case 'moderate spending':
                return {src: moderatespendingImage, shape: 'square'};
            case 'high spending':
                return {src: highspendingImage, shape: '8corners'};
            case 'budget spendings':
                return {src: budgetspendingsImage, shape: 'circle'};
            case 'moderate spendings':
                return {src: moderatespendingsImage, shape: 'triangle'};
            case 'high spendings':
                return {src: highspendingsImage, shape: 'star'};
            case 'budgets spending':
                return {src: budgetsspendingImage, shape: 'rectangle'};
            case 'moderates spending':
                return {src: moderatesspendingImage, shape: 'square'};
            case 'highs spending':
                return {src: highsspendingImage, shape: '8corners'};
            case 'minimalistic style':
                return {src: MinimalisticstyleImage, shape: 'circle'};
            case 'hypebeast style':
                return {src: HypebeaststyleImage, shape: 'triangle'};
            case 'experimental style':
                return {src: ExperimentalstyleImage, shape: 'star'};
            case 'born after 2005':
                return {src: bornafter2005Image, shape: 'rectangle'};
            case 'born between 1997 and 2005':
                return {src: bornbetween1997and2005Image, shape: 'square'};
            case 'born before 1997':
                return {src: bornbefore1997Image, shape: '8corners'};
            case 'influencers':
                return {src: influencersImage, shape: 'circle'};
            case 'hypebeast':
                return {src: HypebeastImage, shape: 'triangle'};
            case 'ssense':
                return {src: SSenseImage, shape: 'star'};
            case 'apperal':
                return {src: apperalImage, shape: 'rectangle'};
            case 'shoes':
                return {src: shoesImage, shape: 'square'};
            case 'accessories':
                return {src: accessoriesImage, shape: '8corners'};
            case 'retail':
                return {src: retailImage, shape: 'circle'};
            case 'website':
                return {src: websiteImage, shape: 'triangle'};
            case 'marketplace':
                return {src: marketplaceImage, shape: 'star'};
            case 'new york':
                return {src: newyorkImage, shape: 'rectangle'};
            case 'london':
                return {src: londonImage, shape: 'square'};
            case 'seoul':
                return {src: seoulImage, shape: '8corners'};
            case 'los angeles':
                return {src: LosAngelesImage, shape: 'circle'};
            case 'paris':
                return {src: ParisImage, shape: 'triangle'};
            case 'tokyo':
                return {src: tokyoImage, shape: 'star'};
            default:
                return null;
        }
    };    

    // Function to create raindrops
    const createRaindrops = () => {
        const rainContainer = document.querySelector('.rain');
        for (let i = 0; i < 100; i++) { // Adjust the number of raindrops
            const drop = document.createElement('div');
            drop.className = 'drop';
            drop.style.left = `${Math.random() * 100}vw`; // Random horizontal position
            drop.style.animationDuration = `${Math.random() * 1 + 0.5}s`; // Random fall speed
            rainContainer.appendChild(drop);
        }
    };

    useEffect(() => {
        if (currentQuestionId === 'Q1' || currentQuestionId === 'Q6') {
            createRaindrops();
        }
        if (currentQuestionId === 'Q8') {
            setShowBouncingBalls(true);
        } else {
            setShowBouncingBalls(false);
        }
    }, [currentQuestionId]);
    
    return (
        <div className="quiz-container" style={{ 
            padding: '20px', 
            background: questionBackgroundColors[currentQuestionId] || 'transparent',
            minHeight: '100vh', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'flex-start' // Ensure content starts from the top
        }}> 
            {showFrontPage && ( // Show front page if showFrontPage is true
                <div className="front-page">
                    <h1>Your Style Says It All </h1> {/* Front page title */}
                    <p>But We're Here to Confirm It.</p> {/* Front page description */}
                    <button onClick={startQuiz} className="start-button">Get Roasted</button> {/* Button to start the quiz */}
                </div>
            )}
            <div className="rain"></div> {/* Rain effect container */}
            <div className="navbar" style={{ backgroundColor: navbarColors[currentQuestionId] }}>
                {/* Navbar content here */}
            </div>
    
            {/* Gradient Background */}
            <div className="gradient-background" style={{
                background: questionBackgroundColors[currentQuestionId],
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -2, // Behind the animated background
            }}></div>
    
            {/* Animated Background */}
            {animatedBackgrounds[currentQuestionId]}
    
            {/* Stars Container */}
            {currentQuestionId === 'Q4' && (
                <div className="stars-container">
                    {Array.from({ length: 10 }, (_, index) => {
                        const starClass = `star star-${index + 1}`;
                        return <div key={index} className={starClass}></div>;
                    })}
                </div>
            )}

            {currentQuestionId === 'Q8' && showBouncingBalls && <BouncingBalls />}

            {/* Show roast and share buttons after the last question */}
            {showRoast ? (
                <div className="roast-container">
                    <h2 className="roast-title">This is only our opinion</h2> {/* Title above the roast */}
                    <p className="roast-text">{roast}</p>
                    <ShareButtons /> {/* Display share buttons */}
                </div>
            ) : (
                currentQuestion && (
                    <div className="question-container">
                        <p className="question-text">{currentQuestion.text}</p> {/* Display the current question text */}
                        <p className="answers-label">
                            {currentQuestionId === 'Q1' ? 'WHAT WOULD YOU WEAR' : 
                             currentQuestionId === 'Q2a' ? 'WHAT WOULD YOU BUY' : 
                             currentQuestionId === 'Q2b' ? 'WHAT WOULD YOU BUY' : 
                             currentQuestionId === 'Q2c' ? 'WHAT WOULD YOU BUY' : 
                             currentQuestionId === 'Q3' ? 'WHERE WOULD YOU LIVE' : 
                             currentQuestionId === 'Q4' ? 'WHAT GIVES YOU NOSTALGIA' : 
                             currentQuestionId === 'Q5' ? 'WHAT INFLUENCES YOU' : 
                             currentQuestionId === 'Q6' ? 'WHAT IS MORE IMPORTANT' : 
                             currentQuestionId === 'Q7' ? 'WHERE WOULD YOU SHOP' : 
                             'WHAT IS YOUR FAVORITE'}
                        </p>
                        <div className="answers-container">
                            <div className="image-grid">
                                {currentQuestion.options.map((option, index) => {
                                    const { src, shape } = getImageForOption(option);
                                    let shapeClass = '';

                                    // Determine the shape class based on the current question
                                    switch (currentQuestionId) {
                                        case 'Q1':
                                            shapeClass = 'square'; // square for Question 1
                                            break;
                                        case 'Q2a':
                                        case 'Q2b':
                                        case 'Q2c':
                                            shapeClass = 'square'; // circle for Questions 2a, 2b, 2c
                                            break;
                                        case 'Q3':
                                            shapeClass = 'rectangle'; // Rectangle for Question 3
                                            break;
                                        case 'Q4':
                                            shapeClass = 'square'; // Square for Question 4
                                            break;
                                        case 'Q5':
                                            shapeClass = 'circle'; // Circle for Question 5 
                                            break;
                                        case 'Q6':
                                            shapeClass = 'rectangle'; // Rectangle for Question 6
                                            break;
                                        case 'Q7':
                                            shapeClass = 'square'; // Square for Question 7
                                            break;
                                        case 'Q8':
                                            shapeClass = 'circle'; // Circle for Question 8
                                            break;
                                        case 'Q9':
                                            shapeClass = 'square'; // Square for Question 9 
                                            break;
                                        default:
                                            shapeClass = 'default-shape'; // Fallback shape class
                                    } // Closing the switch statement
                                    return (
                                        <div className="image-container" key={index}>
                                            <button 
                                                onClick={() => handleOptionSelect(option)} 
                                                className={shapeClass} // Apply shape class
                                            >
                                                {src ? (
                                                    <img src={src} alt={option} className="option-image" />
                                                ) : (
                                                    <span>{option}</span>
                                                )}
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )
            )}
{/* New: Share section with images in boxes */}
<div className="share-buttons">
    <a href={`https://www.instagram.com/create/story?text=I just got roasted! Get roasted on your style too!&url=https://roastmystyle.com/quiz`} target="_blank" rel="noopener noreferrer" className="share-box">
        <img src={instagramLogo} alt="Share on Instagram Story" className="share-icon" />
    </a>
    <a href={`https://twitter.com/intent/tweet?text=I just got roasted! Get roasted on your style too!&url=https://roastmystyle.com/quiz`} target="_blank" rel="noopener noreferrer" className="share-box">
        <img src={xLogo} alt="Prepare to Tweet on Twitter" className="share-icon" />
    </a>
</div>
        </div>
    );
}


export default Quiz;