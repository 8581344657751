// src/CookieConsent.jsx
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './CookieConsent.css'; // Ensure this file exists

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Check if the cookie consent has already been given
        const cookieConsent = Cookies.get('cookieConsent');
        if (!cookieConsent) {
            setIsVisible(true); // Show the pop-up if no consent cookie exists
        }
    }, []);

    const handleAccept = () => {
        Cookies.set('cookieConsent', 'true', { expires: 30 }); // Set cookie for 30 days
        setIsVisible(false); // Hide the pop-up
    };

    const handleDecline = () => {
        setIsVisible(false); // Hide the pop-up
    };

    return (
        isVisible && (
            <div className="cookie-consent">
                <p>We use cookies to make this site extra sweet. Want a bite?</p>
                <button onClick={handleAccept}>Yes, gimme cookies!</button>
                <button onClick={handleDecline}>No thanks, I am on a diet.</button>
            </div>
        )
    );
};

export default CookieConsent; // Ensure this is present