import React from 'react';
import './Logo.css'; // Import the CSS file for styling

const Logo = () => {
    return (
        <div className="logoContainer"> {/* Use a class for styling */}
            <h1 className="logoText"></h1> {/* Use a class for styling */}
        </div>
    );
};

export default Logo;