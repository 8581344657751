import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyABrS_PMuT6CUWAAHGSNOHmZaftMxvtNzw",
    authDomain: "nefer-florence.firebaseapp.com",
    projectId: "nefer-florence",
    storageBucket: "nefer-florence.appspot.com",
    messagingSenderId: "284556993842",
    appId: "1:284556993842:web:6dd83fbbe8b37d0c2be32b"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app); // Initialize auth

export { db, auth };